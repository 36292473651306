@import "../../../../theme/clr_var.scss";

.address-col {
  border: 1px solid #24aae6;
  border-radius: 6px;
  margin-bottom: 10px;
  .custom-switch {
    padding-left: 0.25rem;
  }
  span.share-txt {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
  .form-control {
    margin: 0 10px;
  }
  .edit-btn {
    border: 1px solid #24aae6;
    color: #24aae6;
    padding: 10px 30px;
    margin-bottom: 10px;
    &:hover {
      background: #24aae6;
      color: #fff;
      border-color: #24aae6;
    }
  }
  h4 {
    background: #24aae6;
    padding: 10px 20px;
    border-radius: 6px 6px 0 0;
    font-size: 18px;
  }
  .add-info {
    padding: 0 20px;

    min-height: 120px;
    p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .add-heading {
      color: #24aae6;
    }
    .share {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 15px;
    }
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #24aae6 !important;
    background-color: #24aae6 !important;
  }
  .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: middle;
    float: none;
    width: 100%;
    text-align: center;
    padding-top: 10px;
    padding-left: 36px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    top: 50%;
  }
}
.withdraw-btn {
  background: #24aae6 !important;
  border-color: #24aae6 !important;
  margin-right: 10px;
  border-radius: 6px !important;
  height: 40px;
  padding: 0 30px !important;
  font-size: 16px;
  margin-bottom: 20px;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: fit-content;
  margin: 0 auto;
}
span.blc-info {
  color: #24aae6;
  white-space: nowrap;
}
.table_responsive {
  overflow-x: scroll;
  white-space: nowrap;
  margin: 0 10px;
  table {
    border: 1px solid $secondaryColor;
    border-radius: 8px !important;
    tr {
      th,
      td {
        color: white;
        border: none !important;
        vertical-align: baseline;
        text-align: center;
      }
    }
    thead {
      tr {
        border-bottom: 1px solid $boderColor;
      }
    }
  }
}
.pagination {
  justify-content: center;
  .page-item.active {
    .page-link {
      background-color: #a73e82;
      border-color: #a63f83;
    }
  }
  li {
    a.page-link {
      background-color: transparent;
      color: $white;
      border: 1px solid $boderColor;
      &:hover {
        background-color: #a73f83;
        border-color: #a73f83;
      }
      &:active {
        background-color: #a73f83;
        border-color: #a73f83;
      }
      &:focus {
        box-shadow: unset;
      }
    }
  }
}
