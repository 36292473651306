@import "../../../../theme/clr_var.scss";

.admin_popup {
  background-color: $gradientColor !important;
}

.admin_modal {
  .modal-header {
    background-color: $boderColor !important;
    .close {
      span {
        filter: invert(1);
      }
    }
  }
  .modal-content {
    .form-control {
      max-width: 90%;
      margin: 10px auto;
      &:focus {
        outline: none;
        box-shadow: unset;
        border: 1px solid #ced4da;
      }
    }
    .modal-body {
      color: $dimbg;
    }
  }
  .modal-footer {
    .btn_modal {
      background-color: $boderColor !important;
      border: $boderColor;
    }
  }
}

